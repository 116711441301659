<template>
  <div class="container w-full mx-auto px-4 md:px-0">
    <div class="md:flex md:space-x-5">
      <div class="md:w-1/3">
        <div class="mb-5">
          <ViewTitle :title="$t('cabinet.ownerships.title')" />

          <p class="text-gray-500 mt-3">
            Добавить или удалить сайт который вам принадлежит вы можете в Панели
            Управления на сайте
            <a
              href="https://passport.web.money/asp/aProcess.asp"
              target="_blank"
              class="underline"
            >
              passport.web.money
            </a>
          </p>
        </div>

        <div v-for="ownership in ownerships" :key="ownership.id">
          <SiteSnippet :site="ownership.site" />
        </div>

        <div class="text-center py-16" v-if="ownerships.length <= 0">
          <inbox-icon size="4x" class="block m-auto text-gray-300"></inbox-icon>
          <p class="text-gray-400">
            {{ $t("cabinet.ownerships.no_ownerships") }}
          </p>
        </div>
      </div>

      <div class="md:w-2/3">
        <ViewTitle :title="$t('cabinet.ownerships.ownershipsFeedbacks')" />

        <Feedback
          v-for="feedback in feedbacks"
          :key="feedback.id"
          v-bind:feedback="feedback"
        />

        <div class="text-center py-16" v-if="feedbacks.length <= 0">
          <inbox-icon size="4x" class="block m-auto text-gray-300"></inbox-icon>
          <p class="text-gray-400">
            {{ $t("cabinet.ownerships.noOwnershipsFeedbacks") }}
          </p>
        </div>

        <button
          type="button"
          v-if="showMoreAvailable"
          @click="getUserOwnershipFeedbacks"
          class="
            inline-block
            uppercase
            bg-webmoney
            text-white
            px-5
            py-2
            rounded
            hover:bg-blue-400 hover:text-white
            shadow
            text-sm
            my-4
          "
        >
          {{ $t("shared.more_feedbacks") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SiteSnippet from "@/components/cabinet/SiteSnippet";
import { InboxIcon } from "vue-feather-icons";
import Feedback from "@/components/Feedback";
import ownerships from "@/api/ownerships";
import ViewTitle from "@/components/ViewTitle.vue";

export default {
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
      feedbacks: [],
      page: 1,
      showMoreAvailable: true,
    };
  },
  components: {
    SiteSnippet,
    InboxIcon,
    Feedback,
    ViewTitle,
  },
  created() {
    this.getUserOwnerships();
    this.getUserOwnershipFeedbacks();
  },
  watch: {
    $route: ["getUserOwnerships"],
  },
  computed: mapState({
    ownerships: (state) => state.cabinet.ownerships.list,
  }),
  methods: {
    getUserOwnerships() {
      this.$store.dispatch("cabinet/ownerships/getUserOwnerships", 1);
    },
    getUserOwnershipFeedbacks() {
      ownerships.getOwnershipFeedbacks(this.page).then((data) => {
        console.log(data);
        this.feedbacks = [...this.feedbacks, ...data.feedbacks];
        this.page++;
        if (data.feedbacks.length < 10) {
          this.showMoreAvailable = false;
        }
      });
    },
  },
};
</script>
