<template>
  <div class="border dark:border-gray-700 rounded p-5 mb-5">
    <div class="mb-2">
      <router-link
        :to="
          $i18nRoute({
            name: 'site',
            params: { domain: site.domain },
          })
        "
        class=""
      >
        <div v-if="site.icon" class="inline-block w-10 align-middle mr-2">
          <img
            :src="root + site.icon"
            class="max-w-full max-h-full border dark:border-gray-700 rounded"
          />
        </div>
        {{ site.domain }}
      </router-link>
    </div>

    <div class="">
      <div class="flex items-center mt-4">
        <RatingTag rating="AR" :value="site.advisor_rank" />
        <RatingTag rating="WR" :value="site.webmoney_rank" />
        <RatingTag rating="UR" :value="site.average_feedbacks_point" decimal />

        <div class="inline-block">
          <router-link
            :to="
              $i18nRoute({
                name: 'feedbacks',
                params: { domain: site.domain },
              })
            "
            nohref="true"
            class="cursor-pointer text-sm flex items-center"
          >
            <message-circle-icon
              size="1x"
              class="mr-1 inline-block"
            ></message-circle-icon>
            {{ $tc("shared.feedbacks_count", site.feedbacks_count) }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageCircleIcon } from "vue-feather-icons";
import RatingTag from "@/components/RatingTag.vue";
export default {
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  components: {
    MessageCircleIcon,
    RatingTag,
  },
  props: {
    site: {
      type: Object,
    },
  },
};
</script>

